<template>
  <div class="home">
    <div class="content">
      <!-- :style="{'background-image': `url(${require(`@/assets/img/banner/${bgUrl}`)})`}" -->

      <div class="WebMain">
        <div class="top">
          <p class="p30">十年专注外汇行业，一站式经纪商解决方案</p>
          <img src="../../assets/img/banner/icon.png" alt="" />
        </div>
        <div class="bottom">
          <div class="quotation">
            <div class="Title">
              <p>实时报价</p>
              <p>Real-time quotation</p>
            </div>
            <div >
              <iframe
                src="https://s3-eu-west-1.amazonaws.com/lmax-widget/website-widget-quote-prof-flex.html?a=NRmUV2M0b7BNPtSt&notq=1&brokt1=1"
                frameborder="0"
                style="width: 400px; height: 338px; "
              ></iframe>
            </div>
          </div>
          <div class="imgs">
            <div class="Title">
              <p>账户类型</p>
              <p>Account type</p>
            </div>
            <div class="Account"  @click="toPage(8)">
              <div class="item">
                <img src="../../assets/img/home/英国 (1)@2x.png" alt="" />
                <p class="p14">英国账户 (零售)</p>
              </div>
              <div class="item">
                <img src="../../assets/img/home/新西兰国旗@2x.png" alt="" />
                <p class="p14">新西兰账户</p>
              </div>
              <div class="item">
                <img src="../../assets/img/home/毛里求斯 (1)@2x.png" alt="" />
                <p class="p14">毛里求斯账户</p>
              </div>
            </div>
            <div class="Title">
              <p>获得奖项</p>
              <p>Account type</p>
            </div>
            <div class="Awards" @click="toPage(5)">
              <img src="../../assets/img/home/编组 6@2x.png" alt="" />
            </div>
            
            
          </div>
          <div class="article">
            <div class="topic">
              <div
                v-for="item in newsList"
                :key="item.id"
                @click="changeList(item.id)"
                :class="item.id === activeId ? 'active' : ''"
              >
                <p class="styleBox"></p>
                <p class="p18">
                  {{ item.name }}
                </p>
              </div>
              <i class="el-icon-d-arrow-right" @click="toPath('')"></i>
            </div>
            <div class="list">
              <template v-if="activeList.length > 0">
                <div
                  class="item"
                  v-for="item in activeList.slice(0, 3)"
                  :key="item.id"
                  @click="toPath(item.id)"
                >
                  <p class="title p16">{{ item.title }}</p>
                  <p class="time p14">{{ item.created.slice(0, 10) }}</p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!--背景轮播图-->
      <div class="bannerImgs" v-if="isPhone">
        <img
          class="backImg bg1"
          src="../../assets/img/banner/banner4.png"
          alt=""
        />
        <img
          class="backImg bg2"
          src="../../assets/img/banner/banner5.png"
          alt=""
        />
        <img
          class="backImg bg3"
          src="../../assets/img/banner/banner6.png"
          alt=""
        />
         <img
          class="backImg bg3"
          src="../../assets/img/banner/banner4.png"
          alt=""
        />
      </div>

      <div class="bannerImgs" v-else>
        <img
          class="backImg bg1"
          src="../../assets/img/banner/banner.png"
          alt=""
        />
        <img
          class="backImg bg2"
          src="../../assets/img/banner/banner2.png"
          alt=""
        />
        <img
          class="backImg bg2"
          src="../../assets/img/banner/banner3.png"
          alt=""
        />
        <img
          class="backImg bg1"
          src="../../assets/img/banner/banner.png"
          alt=""
        />
      </div>
      <!-- <img class="backImg" src="../../assets/img/banner/banner.png" alt="" /> -->
    </div>
  </div>
</template>

<script>
import Banner from "@/components/web/Banner.vue";
import { articleDetailAPI, articleListAPI } from "@/api/article/article";
export default {
  components: { Banner },
  data() {
    return {
      newsList: [
        {
          id: 9,
          name: "重要通知",
        },
        {
          id: 11,
          name: "市场新闻",
        },
      ],
      activeId: 9, // 9:重要通知 11：市场新闻
      activeList: [],
      imgList: [
        {
          id: 1,
          path: "banner2.png",
          path2: "phone (1).png",
          title: "九年专注外汇行业，一站式经纪商解决方案",
          icon: "icon (2).png",
        },
        {
          id: 2,
          path: "banner.png",
          path2: "phone (1).png",
          title: "九年专注外汇行业，一站式经纪商解决方案",
          icon: "icon.png",
        },
        {
          id: 3,
          path: "banner3.png",
          path2: "phone (1).png",
          title: "九年专注外汇行业，一站式经纪商解决方案",
          icon: "icon.png",
        },
      ],
      bgUrl: "banner2.png",
      time: 0,
      isPhone: false,
    };
  },
  methods: {
    changeList(val) {
      this.activeId = val;
      this.newsList.forEach((item) => {
        if (item.id === val) {
          // this.activeList = item.list;
          this.getArticleList(val);
        }
      });
    },
    // 获取文章列表
    async getArticleList(val) {
      // this.categoryId = this.$route.params.id
      let info = {
        pageNum: 1,
        pageSize: 4,
        categoryId: val,
      };
      const res = await articleListAPI(info);
      if (res.code === 200) {
        this.activeList = res.data.list;
      } else {
        this.$message({
          type: "info",
          showClose: true,
          message: "列表获取失败!",
        });
      }
    },
    // 跳转到文章详情
    toDetail(val) {
      this.$router.push({
        path: "/list/5",
        query: {
          id: val,
          activeId: this.id,
        },
      });
    },
    //跳转到指定页面
    toPath(val) {
      console.log(val)
      if(val !== '') {
        this.$router.push({
          path: "/list/5",
          query: {
            id: this.activeId,
            articleId: val
          },
        });
      } else {
        this.$router.push({
          path: "/list/5",
          query: {
            id: this.activeId,
          },
        });
      }
        
      
    },
    changeImg(time) {
      let timer = setInterval(() => {
        console.log(this.time);
        if (this.time > 1) {
          this.time = 0;
        } else {
          this.time++;
        }
      }, 5000);
    },
    toPage(id) {
      this.$router.push({
        path:'detail/2',
        query: {
          id
        }
      })
    }
  },
  created() {
    this.changeList(9);
    if (window.innerWidth < 750) {
      this.isPhone = true;
    }
    // this.changeImg();
  },
};
</script>

<style lang="scss" scoped>

.home {
  .content {
    padding-top: 80px;
    padding-bottom: 50px;
    width: calc(100%);
    color: #ffffff;
    overflow: hidden;
    .Title {
      margin-bottom: 17px;
      p {
        &:first-child {
          font-size: 18px;
        }
        &:last-child {
          font-size: 12px;

          color: #e2e2e2;
        }
      }
    }

    .WebMain {
      position: relative;
      .bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 124px;
        .imgs {
          .Awards {
            
            img {
              height: 123px;
              width: 369px;
            }
          }
          .Account {
            margin-bottom: 29px;
            display: flex;
            justify-content: space-between;
            text-align: center;
            .item {
              img {
                height: 68px;
                width: 97px;
              }
            }
          }
        }
        .article {
          width: 360px;
          .topic {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            i {
              color: var(--second-color);
              cursor: pointer;
              margin-left: 50px;
            }
            div {
              // width: 102px;
              cursor: pointer;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              color: #cfcfcf;
              &:first-child {
                margin-right: 100px;
              }
              .styleBox {
                width: 4px;
                height: 18px;
                background: transparent;
                margin-right: 14px;
              }
            }
            .active {
              color: #fff;
              .styleBox {
                background: #fff;
              }
            }
          }
          .list {
            border-top: 1px solid #ffffff;
            .item {
              border-bottom: 1px solid #ffffff;
              max-height: 102px;
              box-sizing: border-box;
              min-height: 102px;
              padding-top: 18px;
              padding-bottom: 11px;
              cursor: pointer;
              .title {
                line-height: 21px;
                display: -webkit-box;
                overflow: hidden;
                word-break: break-all;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
              .time {
                margin-top: 10px;
                text-align: right;
                color: #dfdfdf;
              }
            }
          }
        }
        .quotation{
          height: 350px;
          overflow: hidden;
        }
      }
      .top {
        text-align: right;

        p {
          line-height: 58px;
          text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
        }
        img {
          height: 60px;
          margin-top: 15px;
        }
      }
    }
    .bannerImgs {
      position: absolute;
      top: 0;
      // left:0;
      width: 100%;
      height: 100%;
      z-index: -1;
      padding: 0;
      margin: 0px;
      // animation: img 10s ease-in infinite;
      //  animation-name: img;
      // animation-duration: 24s;
      // animation-timing-function: ease;
      // animation-direction:alternate;
      // animation-delay: 2s;
      // animation-fill-mode: forwards;
      // animation-iteration-count: infinite;
      overflow: hidden;
      display: flex;
     
      img{
        height: 100%;
        width: 100%;
      }
    }
  }
}


@keyframes img {
 
  0% {
    right: 0%;
  }
  10% {
    right: 30%;
  }
  25% {
    right: 50%;
  }
  35% {
    right: 100%;
  }
  40% {
    right: 100%;
  }
  50% {
    right: 200%;
  }
  60% {
    right: 200%;
  }
  70% {
    right: 200%;
  }
  80% {
    right: 300%;
  }
  100% {
    right: 100%;
  }
}
@media screen and (max-width: 1250px) {
  .home .content {
    position: relative;
    padding-bottom: 80px;
    top: 0;
  }
}

@media screen and (max-width: 750px) {
  .home .content {
    // background: rgba($color: #222222, $alpha: 0.05);
    // // background: url('../../assets/img/home/bg.png');
    // background-size: auto;
    // color: black;
  }
}

</style>